<template>
  <div class="">
    <ControlNavbar/>
    <page-header :title="bill.label" v-if="bill"/>

    <div class="container-fluid">
      <Banner :banner="bill.banner"/>
      <br/>
      <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
      >
        <a-form-item :label="locale.country" bill.data.countries>
          <a-select
              v-decorator="[
              'countryCode',
              {
                initialValue: bill.data.defaultCountry,
                rules: [{ required: true, message: locale.emptyFieldsWarning }],
              },
            ]"
          >
            <a-select-option
                v-for="country in bill.data.countries"
                :key="country"
                :value="country"
            >
              {{ countries[country.toUpperCase()] }}
              <img
                  :src="require(`../assets/flags/${country}.png`)"
                  width="35px"
                  alt=""
              />
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="locale.billType" v-if="bill.data.billTypes">
          <a-select
              @change="handleBillTypeChange"
              v-decorator="[
              'typeId',
              {
                initialValue: bill.data.defaultBillTypeId,
                rules: [{ required: true, message: locale.emptyFieldsWarning }],
              },
            ]"
          >
            <a-select-option
                v-for="type in bill.data.billTypes"
                :key="type.typeId"
                :value="type.typeId"
            >
              {{ type.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
            :label="bill.data.hint"
            v-if="billType === 'phone' || billType === 'numeric'"
        >
          <a-input-number
              style="width: 100%"
              v-decorator="[
              'msisdn',
              {
                rules: [{ required: true, message: locale.emptyFieldsWarning }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="bill.data.hint" v-else-if="billType === 'text'">
          <a-input
              style="width: 100%"
              v-decorator="[
              'msisdn',
              {
                rules: [{ required: true, message: locale.emptyFieldsWarning }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="danger" html-type="submit">
            {{ bill.data.retrieve }}
          </a-button>
        </a-form-item>
      </a-form>
      <a-modal
          v-model="visible"
          :title="`${billDetails.header.label} ${billDetails.header.text}`"
          v-if="billDetails"
          :footer="null"
      >
        <div
            v-if="billDetails && billDetails.amount && billDetails.allowPayAll"
        >
          <label for="payAllSwitch">{{ billDetails.amountToggle }}: </label>
          <a-switch id="payAllSwitch" @change="amountToggled"/>
        </div>
        <div v-if="billDetails.pairs" class="billPairs">
          <p v-for="(pair, index) in billDetails.pairs" :key="index">
            {{ pair.label }} {{ pair.text }}
          </p>
        </div>
        <!-- if amount object null => full bill else partial -->
        <!-- if amount not null and allowPayAll true put partial/complete bill toggle (with label as amountToggle) -->
        <!-- if allowPayAll false don't put the toggle -->
        <a-form
            :form="modalForm"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
            @submit="handlePayBill"
        >
          <a-form-item
              :label="billDetails.amount.text"
              v-if="!payAll && billDetails.amount"
          >
            <a-input-number
                style="width: 100%"
                :min="billDetails.amount.min / 10000"
                :max="billDetails.amount.max / 10000"
                :step="billDetails.amount.increment / 10000"
                validate-status="success"
                @change="
                (e) => {
                  handleBillAmount(
                    e,
                    billDetails.amount.min / 10000,
                    billDetails.amount.max / 10000
                  );
                }
              "
                v-decorator="[
                `amount`,
                {
                  rules: [
                    {
                      required: true,
                      message: `${billDetails.amount.invalidAmountMessage}`,
                    },
                    {
                      type: 'number',
                      max: billDetails.amount.max / 10000,
                      min: billDetails.amount.min / 10000,
                      message: billDetails.amount.invalidAmountMessage,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <p v-if="!payAll">{{ price }}</p>
          <p v-if="payAll">{{ locale.price_to_pay }} {{ payAllPrice }}</p>
          <div
              v-if="billDetails && billDetails.amount && billDetails.allowPayAll"
          >
            <br/>
            <label for="sms">{{ bill.data.toggle }}</label>
            <a-switch id="sms" @change="smsToggled"/>
            <a-form-item label="contact" v-if="sms">
              <a-input
                  v-decorator="[
                  `contact`,
                  {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>

          <a-form-item :wrapper-col="{ span: 12, offset: 10 }">
            <a-button type="danger" @click="handlePayBill">
              {{ bill.data.pay }}
            </a-button>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
        v-if="billDetails"
    >
      <div v-if="showPaymentOptions">
        <div
            class="
            d-flex
            justify-content-between
            card_custom
            pointer
            align-items-center
          "
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <p>{{ option.label }}</p>
          <p>
            {{ billDetails.prices[`${option.id}`] }}
          </p>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldBill="handleOldBill"
      />
    </a-modal>
    <loading :loading="loader"/>
    <a-modal
        v-model="otpModalVisible"
        v-if="otpModalObject"
        :title="otpModalObject.label"
        :okText="otpModalObject.button"
        @ok="closeOtpModal"
    >
      <a-input v-model="otpModalData" :placeholder="otpModalObject.hint"/>
    </a-modal>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import countriesCode from "../scripts/countries";
import axios from "axios";
// import Slick from "vue-slick";

// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "SpecificBill",
  components: {ControlNavbar, Banner, PaymentCC, Loading},
  data() {
    return {
      locale: null,

      // otp for bill
      otpModalVisible: false,
      otpModalObject: null,
      otpModalData: null,
      // end otp for bill

      loader: false,
      sms: false,
      price: "",
      payAllPrice: "",
      billActualPrice: null,
      payAll: false,
      billDetails: null,
      visible: false,

      countries: countriesCode.countriesCode,
      billType: "phone",
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "billForm"}),
      modalForm: this.$form.createForm(this, {name: "billFormModal"}),
      bill: null,

      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },

    // otp modal functions
    closeOtpModal() {
      this.otpModalVisible = false;
      this.otpModalObject = null;
      this.handleSubmit();
    },
    openOtpModal() {
      this.otpModalVisible = true;
      this.otpModalData = null;
    },
    // end otp modal functions
    // credit card payment function

    selectPaymentOption(payOption) {
      console.log(payOption);
      // when clicked on the price fields of payment options if credit card open credit card commponent and give it the values that it needs to send to the api
      if (payOption.type == "creditcard") {
        this.apiBody.type = this.bill.type;
        this.apiBody.fetchPrice = true;
        this.apiBody.denominationId = this.bill.data.id;
        this.apiBody.optionId = payOption.id;
        this.apiBody.type = this.bill.type;
        this.paymentOption = payOption.type;
      } else {
        //
        this.paymentOptionsVisible = false;
        this.payBillByWhish(payOption);
      }
    },
    isEmpty(obj) {
      for (var o in obj) {
        if (o) {
          return false;
        }
      }
      return true;
    },
    checkPaymentMethod() {
      // the whole cc payment is here just check payment solution and the modal will do the rest

      // always check prices in voucher object and server.paymentOptions
      // if both are not null
      // if topleft menu (default_payment exists) && usertogglevalue is true do api aade
      // else

      // get intersect between prices and server.paymentoptions (paymentoptions.id == voucher.prices['id']) id is the key of the json
      // if empty do normal api
      //WHISHPAYMENT AND ANY OTHER else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected
      // CREDIT CARD else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected

      // if both or one of them null continue aade
      this.paymentOption = null;
      if (
          this.$session.get("paymentOptions") &&
          !this.isEmpty(this.billDetails.prices)
      ) {
        if (
            this.$session.get("userToggleValue") == true &&
            this.$session.get("default_payment") == true
        ) {
          console.log("do normal api");
          // do normal api
          return true;
        } else {
          console.log("methods");
          // check payments option
          this.paymentOptionsVisible = true;
          this.showPaymentOptions = true;
          return false;
        }
      } else {
        console.log("normal api");
        // do normal api
        return true;
      }
    },

    // end cc payment functions

    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error(`error in (${result.config.url})`, result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    smsToggled(checked) {
      this.sms = checked;
    },
    amountToggled(checked) {
      // if true pay all bill
      this.payAll = checked;
      this.price = null;
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    handleSubmit(e) {
      console.log("handle submit");
      if (e) {
        e.preventDefault();
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          values.countryCode = this.countries[
              `${values.countryCode.toUpperCase()}`
              ];
          if (this.otpModalData) {
            values.otp = this.otpModalData;
          }
          values.msisdn = `${values.msisdn}`;
          values.id = this.bill.data.id;
          console.log("Received values of form: ", values);
          this.setLoader(true);
          this.getAxios()
              .post(`${this.$session.get("baseURI")}sale/bill/fetch`, values)
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, values, "sale/bill/fetch", "post")) {
                  // ok
                  console.log("after first submit", result);
                  let otpdialog = result.data.data.dialog;
                  if (otpdialog) {
                    console.log("otp should show");
                    // show otp dialog
                    this.otpModalObject = otpdialog;
                    this.openOtpModal();
                  } else {
                    this.billDetails = result.data.data;
                    console.log("show modal");
                    this.payAllPrice = this.billDetails.deductibleAmountText;
                    this.showModal();
                  }
                }
              });
        }
      });
    },
    handleBillAmount(e, min, max) {
      //       {
      //     "price": "16 AED",
      //     "prices": {},
      //     "currentPrice": 1600
      // }
      if (e >= min && e <= max) {
        this.setLoader(true);
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/bill/cost`, {
              id: this.billDetails.billId,
              amount: e * 10000,
            })
            .then((result) => {
              this.setLoader(false);
              if (this.checkApiRes(result, {
                id: this.billDetails.billId,
                amount: e * 10000,
              }, "sale/bill/cost", "post")) {
                // ok
                this.price = result.data.data.price;
                this.billActualPrice = result.data.data.currentPrice;
                console.log("getting cost: ", result);
              }
            });
      } else {
        this.price = "invalid amount";
      }
    },
    handlePayBill(e) {
      // send contact null if the object toggle is not available
      //  id is billId
      e.preventDefault();
      console.log("handlepaybill");
      this.modalForm.validateFields((err, values) => {
        if (!err) {
          if (this.payAll) {
            //pay all
            // if user is paying all amount should be null
            values.id = this.billDetails.billId;
            values.price = this.billDetails.currentPrice;
            values.amount = null;
            values.payAll = true;
            this.apiBody = values;
            if (this.checkPaymentMethod()) {
              this.setLoader(true);
              this.getAxios()
                  .post(
                      `${this.$session.get("baseURI")}sale/bill/payment`,
                      values
                  )
                  .then((result) => {
                    this.setLoader(false);
                    if (this.checkApiRes(result, values, "sale/bill/payment", "post")) {
                      // ok
                      this.success(
                          result.data.dialog.title,
                          result.data.dialog.message
                      );
                      this.handleOk();
                      console.log("full bill payment: ", result);
                    }
                  });
            }
          } else {
            // get cost
            //partial amount send amount that user inputed
            values.payAll = false;
            values.price = this.billActualPrice;
            values.amount *= 10000;
            values.id = this.billDetails.billId;
            //if contact toggle is null put contact null else take it from field named contact
            if (!values.contact) {
              values.contact = null;
            }
            console.log("values to be payed:", values);
            this.apiBody = values;
            if (this.checkPaymentMethod()) {
              this.setLoader(true);
              this.getAxios()
                  .post(
                      `${this.$session.get("baseURI")}sale/bill/payment`,
                      values
                  )
                  .then((result) => {
                    this.setLoader(false);
                    if (this.checkApiRes(result, values, "sale/bill/payment", "post")) {
                      // ok
                      this.success(
                          result.data.dialog.title,
                          result.data.dialog.message
                      );
                      this.handleOk();
                      console.log("partial bill payment: ", result);
                    }
                  });
            }
          }
          console.log("Received values of form: ", values);
        }
      });
    },
    payBillByWhish(payOption) {
      // send contact null if the object toggle is not available
      //  id is billId
      console.log("handlepaybill");
      this.modalForm.validateFields((err, values) => {
        if (!err) {
          values.optionId = payOption.id;

          if (this.payAll) {
            //pay all
            // if user is paying all amount should be null
            values.id = this.billDetails.billId;
            values.price = this.billDetails.currentPrice;
            values.amount = null;
            values.payAll = true;
            this.setLoader(true);
            this.getAxios()
                .post(`${this.$session.get("baseURI")}sale/bill/payment`, values)
                .then((result) => {
                  this.setLoader(false);
                  if (this.checkApiRes(result, values, "sale/bill/payment", "post")) {
                    // ok
                    this.success(
                        result.data.dialog.title,
                        result.data.dialog.message
                    );
                    this.handleOk();
                    console.log("full bill payment: ", result);
                  }
                });
          } else {
            // get cost
            //partial amount send amount that user inputed
            values.payAll = false;
            values.price = this.billActualPrice;
            values.amount *= 10000;
            values.id = this.billDetails.billId;
            //if contact toggle is null put contact null else take it from field named contact
            if (!values.contact) {
              values.contact = null;
            }
            console.log("values to be payed:", values);
            this.setLoader(true);
            this.getAxios()
                .post(`${this.$session.get("baseURI")}sale/bill/payment`, values)
                .then((result) => {
                  this.setLoader(false);
                  if (this.checkApiRes(result, values, "sale/bill/payment", "post")) {
                    // ok
                    this.success(
                        result.data.dialog.title,
                        result.data.dialog.message
                    );
                    this.handleOk();
                    console.log("partial bill payment: ", result);
                  }
                });
          }
          console.log("Received values of form: ", values);
        }
      });
    },
    success(title, message) {
      let self = this;
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
        okText: self.locale.ok,
        onOk() {
          self.$router.push({
            name: "Home",
          });
        },
      });
    },
    handleBillTypeChange(e) {
      this.bill.data.billTypes.forEach((billType) => {
        if (billType.typeId == e) {
          this.billType = billType.inputType;
        }
      });
    },
    handleOldBill(apiBody) {
      this.paymentOptionsVisible = false;
      if (apiBody.payAll) {
        // pay all
        this.setLoader(true);
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/bill/payment`, apiBody)
            .then((result) => {
              this.setLoader(false);
              if (this.checkApiRes(result, apiBody, "sale/bill/payment", "post")) {
                // ok
                this.success(
                    result.data.dialog.title,
                    result.data.dialog.message
                );
                console.log("full bill payment: ", result);
              }
            });
      } else {
        // pay partial
        this.setLoader(true);
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/bill/payment`, apiBody)
            .then((result) => {
              this.setLoader(false);
              if (this.checkApiRes(result, apiBody, "sale/bill/payment", "post")) {
                // ok
                this.success(
                    result.data.dialog.title,
                    result.data.dialog.message
                );
                console.log("partial bill payment: ", result);
              }
            });
      }
    },
  },
  created() {
    this.checkLocal();
    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldBill(values);
    }
    if (!this.$route.params.bill) {
      // undefined
      this.bill = this.$session.get("backup");
    } else {
      this.$session.set("backup", this.$route.params.bill);
      this.bill = this.$route.params.bill;
    }
    if (!this.bill.data.toggleOptional) {
      this.sms = true;
    }
    console.log("bill: ", this.bill);
  },
};
</script>

<style lang="scss" scoped>
.billPairs {
  background-color: grey;
  border-radius: 45px;
  color: white;
  padding: 5%;
}

.header {
  padding: 20px;
  background: #e6e6e6;
  box-shadow: 7px 6px 5px grey;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 70px;
  margin-bottom: 10px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
